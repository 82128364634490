<template>
    <header class="login">
        <van-nav-bar title="企业注册" left-text="返回" left-arrow @click-left="back"></van-nav-bar>

        <van-form class="login_form" @submit="register">
            <van-field class="form_input" v-model="form.companyName" name="companyName" label="公司名称" placeholder="请输入公司名称" :rules="rules.companyName" />
            <van-field class="form_input" v-model="form.name" name="name" label="姓名" placeholder="请输入姓名" :rules="rules.name" />
            <van-field class="form_input" v-model="form.phone" name="phone" label="手机号" placeholder="请输入手机号" :rules="rules.phone" />
            <div class="form_tip">手机号为负责人登录账号，默认密码：<span>000000</span></div>
            <div class="form_btn" style="margin: 16px;">
                <van-button round block type="info" native-type="submit"> 注册 </van-button>
            </div>
        </van-form>
    </header>
</template>

<script>
    import { constants } from "crypto";
    export default {
        data() {
            return {
                form: {
                    companyName: '',
                    name: '',
                    phone: ''
                },
                rules: {
                    companyName: [{ required: true, message: '请输入公司名称' }],
                    name: [{ required: true, message: '请输入姓名' }],
                    phone: [
                        { required: true, message: '请输入手机号' },
                        { validator: this.phoneValidator, message: '手机号格式错误' },
                    ]
                }
            };
        },
        methods: {
            phoneValidator(val) {
                return /1\d{10}/.test(val);
            },

            register() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/user/insertCompany", this.form)
                .then(res => {
                    if(res.code == "ok") {
                        toast.clear();
                        this.$toast.success('注册成功');
                        this.$router.push("/login");
                    } else {
                        toast.clear();
                        this.$toast.fail('注册失败');
                    }
                }).catch(err=> {toast.clear();});
            },
            
            back() {
                this.$router.push("/login");
            }
        },
    };
</script>

<style lang="less" scoped>
    .logo {
        font-size: 100px !important;
        margin-bottom: 150px;
    }

    /* 本页公共样式 */
    .login {
        height: 100vh;
        background-color: #fff;
    }

    header {
        text-align: center;
    }

    .login_form {
        padding-top: 20px;
        .form_input {
            margin: 0 0 30px 0;
        }

        .form_btn {
            width: 80%;
            margin: 0 auto !important;
            button {
                background-color: #20a0ff;
            }
        }

        .form_tip {
            margin: 0 0 30px 0;
            font-size: 14px;
            color: #afafaf;
            span {
                color: #ff0000;
            }
        }
    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
</style>

